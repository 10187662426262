<template>
  <DataTable
    :value="data"
    responsiveLayout="scroll"
    breakpoint="960px"
    stripedRows
    removableSort
    dataKey="id"
    :paginator="true"
    :rows="rows"
    class="container-desktop"
  >
    <template #header v-if="label">
      {{ label }}
    </template>
    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      v-for="(col, idx) in columns"
      :key="col.id"
      :field="col.field"
      :header="col.header"
      :sortable="col.sort"
      headerStyle="min-width: 6rem"
      :class="
        col.currency ? 'right' : col.date || col.center ? 'center' : 'left'
      "
    >
      <template v-if="col.currency" #body="slotProps">
        {{ currency.convertNumberToCurrency(slotProps.data[col.field]) }}
      </template>
      <template v-else-if="col.period" #body="slotProps">
        {{
          slotProps.data[col.field]
            ? dates.getPeriodToString(slotProps.data[col.field])
            : ""
        }}
      </template>
      <template v-else-if="col.date" #body="slotProps">
        {{
          slotProps.data[col.field]
            ? dates.dateToString(slotProps.data[col.field])
            : ""
        }}
      </template>
      <template v-else-if="col.time" #body="slotProps">
        {{
          slotProps.data[col.field]
            ? dates.timeToString(slotProps.data[col.field])
            : ""
        }}
      </template>
      <template v-else-if="idx === 0 && linked" #body="slotProps">
        <strong class="name-strong" @click="onSelect(slotProps.data)">
          {{ slotProps.data[col.field] }}</strong
        >
      </template>
      <template v-else #body="slotProps">
        {{ slotProps.data[col.field] }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { currency, dates } from "../../constants";

export default {
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    linked: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: (row) => console.log(row),
    },
  },
  setup() {
    return { currency, dates };
  },
};
</script>

<style scoped>
.container-desktop {
  margin: 0 4rem;
  max-width: calc(100vw - 8rem);
  display: none;
}
.name-strong {
  cursor: pointer;
  color: var(--primary-dark);
}
.name-strong:hover {
  text-decoration: underline;
}
@media screen and (min-width: 1440px) {
  .container-desktop {
    display: block;
  }
}
</style>
