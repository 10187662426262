<template>
  <div class="title">
    <h2 :class="error ? 'error' : 'primary'">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.title {
  margin-top: 1rem;
  text-align: center;
}
.title > h2 {
  font-size: 1.2rem;
}
.primary {
  color: var(--primary);
}
.error {
  color: red;
}
</style>
