<template>
  <div class="title">
    <h2 :class="error ? 'error' : secondary ? 'secondary' : 'primary'">
      {{ title }}
    </h2>
    <p>{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  padding: 0 2rem;
}
.title > h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.title > p {
  font-size: 0.8rem;
  color: var(--primary);
}
.primary {
  color: var(--primary);
}
.secondary {
  color: var(--primary-dark);
}
.error {
  color: red;
}
</style>
