<template>
  <Dialog>
    <template #header>
      <h3>{{ title }}</h3>
    </template>

    <slot name="dialog"></slot>

    <!-- <template #footer>
      <Button label="Eliminar" icon="pi pi-times" class="p-button-text p-button-danger" @click="onCancel"/>
      <Button label="Reservar" icon="pi pi-check" class="button-accept" autofocus @click="onAccept" />
    </template> -->
  </Dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    onCancel: {
      type: Function,
      default: (e) => { console.log('Cancelled!') }
    },
    onAccept: {
      type: Function,
      default: (e) => { console.log('Accepted!') }
    }
  }
}
</script>

<style scoped>
.button-accept {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  box-shadow: none !important;
}
</style>