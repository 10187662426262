<template>
  <Button :label="label" class="button" @click="click" />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Button",
    },
    click: {
      type: Function,
      default: () => alert("Button"),
    },
  },
};
</script>

<style scoped>
.button {
  width: 190px;
  height: 50px;
  background-color: var(--primary) !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  font-size: 1.2rem !important;
  border-radius: 0.5rem !important;
}
.button:hover {
  background-color: var(--primary-light) !important;
  color: var(--primary) !important;
}
</style>
