<template>
  <div class="tower">
    <MobileTable
      v-model:selection="store.state.tower.selectedTowers"
      :label="$route.name"
      :columns="tower.columns"
      :data="store.state.tower.towers"
    />
    <DesktopTable
      v-model:selection="store.state.tower.selectedTowers"
      :label="$route.name"
      :columns="tower.columns"
      :data="store.state.tower.towers"
    />
    <Actions :items="tower.actions" />
    <BasicModal v-model:visible="tower.showDialog">
      <template v-slot:dialog>
        <BasicTitle title="Datos de la Torre" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />
        <div class="container-form">
          <BasicLabel label="Nombre *" />
          <BorderInput
            v-model="tower.data.name"
            label="Nombre *"
            @keyup.enter="onSave"
          />
          <FormError :show="tower.rules.name" />
          <BasicLabel label="Pisos" />
          <BorderInput
            v-model="tower.data.floors"
            label="Pisos"
            type="number"
            @keyup.enter="onSave"
          />
          <FormError
            :show="tower.rules.floors"
            message="Ingrese un número entero"
          />
          <BasicLabel label="Ascensores" />
          <BorderInput
            v-model="tower.data.elevators"
            label="Ascensores"
            type="number"
            @keyup.enter="onSave"
          />
          <FormError
            :show="tower.rules.elevators"
            message="Ingrese un número entero"
          />
          <PrimaryButton label="Guardar" :click="onSave" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import store from "../../store";
import { openMode, actions, validation } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    BasicModal,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      tower.showDialog = true;
    };
    const onModify = () => {
      if (store.state.tower.selectedTowers.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        const selected = store.state.tower.selectedTowers[0];
        tower.data.id = selected.id;
        tower.data.buildingId = selected.buildingId;
        tower.data.name = selected.name;
        tower.data.floors = selected.floors;
        tower.data.elevators = selected.elevators;
        tower.showDialog = true;
      }
    };
    const onRemove = async () => {
      if (store.state.tower.selectedTowers.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.towerActions.delete,
          store.state.tower.selectedTowers[0].id
        );
        if (response.ok) {
          store.commit("setSelectedTowers", []);
          await store.dispatch(
            actions.towerActions.findAll,
            store.state.building.selectedBuildings[0].id
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const tower = reactive({
      showDialog: false,
      data: {
        id: "",
        buildingId: store.state.building.selectedBuildings[0].id,
        name: "",
        floors: "",
        elevators: "",
      },
      rules: {
        name: false,
        floors: false,
        elevators: false,
      },
      columns: [
        {
          field: "name",
          header: "Torre",
          sort: true,
        },
        {
          field: "floors",
          header: "Pisos",
          sort: true,
        },
        {
          field: "elevators",
          header: "Ascensores",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/torres/asociar"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/torres/asociar"),
        },
        {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/complejos/torres/asociar"),
        },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!tower.data.name) {
        tower.rules.name = true;
        valid = false;
      }
      if (
        tower.data.floors &&
        !validation.numberRegex.test(tower.data.floors)
      ) {
        tower.rules.floors = true;
        valid = false;
      }
      if (
        tower.data.elevators &&
        !validation.numberRegex.test(tower.data.elevators)
      ) {
        tower.rules.elevators = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: tower.data.id,
        buildingId: tower.data.buildingId,
        name: tower.data.name,
        floors: tower.data.floors,
        elevators: tower.data.elevators,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(actions.towerActions.update, request);
      } else {
        response = await store.dispatch(actions.towerActions.create, request);
      }
      if (response.ok) {
        store.commit("setSelectedTowers", []);
        tower.showDialog = false;
        tower.data.name = "";
        tower.data.floors = "";
        tower.data.elevators = "";
        await store.dispatch(
          actions.towerActions.findAll,
          store.state.building.selectedBuildings[0].id
        );
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedTowers", []);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.towerActions.findAll,
        store.state.building.selectedBuildings[0].id
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (tower.data.name) tower.rules.name = false;
      if (
        !tower.data.floors ||
        (tower.data.floors && validation.numberRegex.test(tower.data.floors))
      )
        tower.rules.floors = false;
      if (
        !tower.data.elevators ||
        (tower.data.elevators &&
          validation.numberRegex.test(tower.data.elevators))
      )
        tower.rules.elevators = false;
    });

    return { store, tower, onSave };
  },
};
</script>

<style scoped>
.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
