<template>
  <DataTable
    :value="data"
    v-model:expandedRows="expandedRows"
    responsiveLayout="scroll"
    breakpoint="960px"
    stripedRows
    removableSort
    dataKey="id"
    :paginator="true"
    :rows="rows"
    class="container-mobile"
  >
    <template #header v-if="label">
      {{ label }}
    </template>
    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      v-for="index in columns.length > 1 ? 2 : 1"
      :key="columns[index - 1].field"
      :field="columns[index - 1].field"
      :header="columns[index - 1].header"
      :sortable="columns[index - 1].sort"
      :class="columns[index - 1].currency ? 'right' : 'center'"
    >
      <template v-if="columns[index - 1].currency" #body="slotProps">
        {{
          currency.convertNumberToCurrency(
            slotProps.data[columns[index - 1].field]
          )
        }}
      </template>
      <template v-else-if="columns[index - 1].period" #body="slotProps">
        {{ dates.getPeriodToString(slotProps.data[columns[index - 1].field]) }}
      </template>
      <template v-else-if="columns[index - 1].date" #body="slotProps">
        {{ dates.dateToString(slotProps.data[columns[index - 1].field]) }}
      </template>
      <template v-else-if="columns[index - 1].time" #body="slotProps">
        {{ dates.timeToString(slotProps.data[columns[index - 1].field]) }}
      </template>
      <template v-else-if="index === 1 && linked" #body="slotProps">
        <strong class="name-strong" @click="onSelect(slotProps.data)">
          {{ slotProps.data[columns[index - 1].field] }}</strong
        >
      </template>
      <template v-else #body="slotProps">
        {{ slotProps.data[columns[index - 1].field] }}
      </template>
    </Column>
    <Column :expander="columns.length > 2" headerStyle="width: 3rem" />
    <template #expansion="slotProps">
      <div
        v-for="(key, idx) in Object.keys(slotProps.data)"
        :key="key"
        class="detail"
      >
        <p v-if="getHeader(key)" class="detail-header">{{ getHeader(key) }}</p>
        <p v-if="getHeader(key)">
          {{
            isCurrency(key)
              ? currency.convertNumberToCurrency(
                  Object.values(slotProps.data)[idx]
                )
              : isDate(key)
              ? dates.dateToString(Object.values(slotProps.data)[idx])
              : isTime(key)
              ? dates.timeToString(Object.values(slotProps.data)[idx])
              : isPeriod(key)
              ? dates.getPeriodToString(Object.values(slotProps.data)[idx])
              : Object.values(slotProps.data)[idx]
          }}
        </p>
      </div>
    </template>
  </DataTable>
</template>

<script>
import { currency, dates } from "../../constants";
import { ref } from "vue";

export default {
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    linked: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: (row) => console.log(row),
    },
  },
  setup(props) {
    const expandedRows = ref(null);
    const getHeader = (key) => {
      const column = props.columns.find((item) => item.field === key);
      if (column) return column.header;
      return null;
    };
    const isCurrency = (key) => {
      const column = props.columns.find((item) => item.field === key);
      if (column) return column.currency;
      return false;
    };
    const isDate = (key) => {
      const column = props.columns.find((item) => item.field === key);
      if (column) return column.date;
      return false;
    };
    const isTime = (key) => {
      const column = props.columns.find((item) => item.field === key);
      if (column) return column.time;
      return false;
    };
    const isPeriod = (key) => {
      const column = props.columns.find((item) => item.field === key);
      if (column) return column.period;
      return false;
    };
    return {
      currency,
      dates,
      expandedRows,
      getHeader,
      isCurrency,
      isDate,
      isTime,
      isPeriod,
    };
  },
};
</script>

<style scoped>
.container-mobile {
  margin: 0 2rem;
}
.detail {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0 1rem;
}
.detail > p {
  padding: 1rem 0;
  font-size: 0.9rem;
}
.detail-header {
  color: var(--primary-dark);
}
.name-strong {
  cursor: pointer;
  color: var(--primary-dark);
}
.name-strong:hover {
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .container-mobile {
    margin: 0 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .container-mobile {
    display: none;
  }
}
</style>
