<template>
  <div :class="right ? 'actions actions-right' : 'actions'">
    <router-link
      v-for="item in items.filter((item) => item.hidden)"
      :key="item.name"
      to=""
      @click.prevent="item.action"
      >{{ item.name }}</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.actions {
  margin: 0 2rem 2rem;
  display: flex;
}
.actions > a {
  color: var(--primary-dark);
  margin-right: 1.5rem;
}
.actions-right {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 2rem -1.5rem !important;
}
@media screen and (min-width: 768px) {
  .actions {
    margin: 0 4rem 2rem;
  }
  .actions-right {
    margin: 2rem 4rem -1.5rem !important;
  }
}
</style>
