<template>
  <p class="label" :style="`width: ${width}; text-align: ${align};`">
    {{ label }}
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Mensaje",
    },
    width: {
      type: String,
      default: "350px",
    },
    align: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style scoped>
.label {
  margin: 0;
  font-size: 0.9rem;
  color: var(--primary);
}
</style>
