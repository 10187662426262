<template>
  <div class="error">
    <small id="username2-help" class="p-error">{{ show ? message : "" }}</small>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Este campo es requerido.",
    },
  },
};
</script>

<style scoped>
.error {
  width: 350px;
  margin-bottom: 1.5rem;
  text-align: left;
}
</style>
