<template>
  <InputText :type="type" :value="label" disabled :class="`input`" />
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    label: {
      type: String,
      default: "Input",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  setup() {
    const invalid = ref(false);

    return { invalid };
  },
};
</script>

<style scoped>
.input {
  width: 60px;
  height: 50px;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2px solid var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 10px 5px -5px rgba(35, 141, 130, 0.15) !important;
  margin-bottom: 0.5rem;
  margin-right: 20px;
  color: var(--primary);
}
.input::placeholder {
  color: var(--primary) !important;
}
</style>
