<template>
  <Dropdown
    :options="options"
    optionLabel="name"
    optionValue="id"
    :placeholder="label"
    emptyMessage="No hay datos"
    emptyFilterMessage="No hay resultados"
    class="select"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Select",
    },
    options: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.select {
  height: 50px;
  width: 350px;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2px solid var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 10px 5px -5px rgba(35, 141, 130, 0.15) !important;
  margin-bottom: 0.5rem;
}
</style>
